<template>
  <div class="home">
    <div class="headerBox">
      <div class="leftBox">
        <div class="logo"></div>
        <div class="appInfo">
          <div class="title">小湿妹 全网最全的导管APP都在这里</div>
          <div class="desc">永久地址：{{ url }}</div>
        </div>
      </div>
      <div class="rightBox" @click="showSave = !showSave">收藏网址</div>
    </div>
    <div class="headerTip">
      {{ notice }}
    </div>
    <!-- 轮播图广告 -->
    <AdvSwiper
      :advList="advList"
      :key="advList[0].id"
      v-if="advList.length"
      class="advSwiper"
    />
    <van-tabs
      v-model="activeIndex"
      class="myTabs"
      background="#ffffff"
      swipeable
      :lazy-render="true"
    >
      <van-tab
        :title="item.name"
        v-for="(item, index) in tabList"
        :key="item.id"
      >
        <BlackMaterial
          v-if="item.name == '爆料吃瓜'"
          :moduleId="item.cid"
          :internalUrl="item.internalUrl"
          @getInfo="getInfo"
        />
        <TabItem
          @getInfo="getInfo"
          v-else-if="activeIndex == index"
          :moduleId="item.cid"
        />
      </van-tab>
    </van-tabs>
    <!-- 轮播图广告 -->
    <AdvsSwiper
      :advList="downBanner"
      v-if="downBanner.length"
      :key="downBanner[0].id"
      :hasPagination="false"
      class="downBanner"
    />
    <van-overlay :show="advShow" z-index="8">
      <div v-if="popUpList.length > 0" class="advertise_box">
        <div
          class="announcement_box_img"
          v-for="item in popUpList"
          :key="item.id"
        >
          <div class="content_img" @click="goAdv(item)">
            <ImgDecypt :key="item.id" :src="item.icon"></ImgDecypt>
          </div>
        </div>
        <div class="imgBtn" @click="close('img')"></div>
      </div>
    </van-overlay>
    <van-overlay :show="showSave" z-index="9">
      <div class="qrcodeBox" @click.stop>
        <div class="qrcodeTitle">保存二维码</div>
        <div class="qrcode">
          <div ref="qrCodeUrl"></div>
        </div>
        <div class="btnBox" @click="downloadQrcode">下载二维码图片</div>
        <div class="btnBox" @click="saveLink">复制链接保存</div>
        <div class="btnBox" @click="showSave = false">关闭</div>
        <div class="kindTip">
          同时浏览器右上角菜单，可设置将吞精兽添加至手机桌面打开更便捷永不迷路！
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getTabs, visitRecord } from "@/api/home";
import TabItem from "./components/tabItem.vue";
import BlackMaterial from "./components/blackMaterial.vue";
import domToImage from "dom-to-image";
import { Toast } from "vant";
import QRCode from "qrcodejs2";
import AdvSwiper from "@/components/AdvSwiper";
import AdvsSwiper from "@/components/AdvsSwiper";
import { jumpAdvs } from "@/utils/getConfig";

export default {
  name: "home",
  components: {
    TabItem,
    BlackMaterial,
    AdvSwiper,
    AdvsSwiper,
    ImgDecypt: () => import("@/components/ImgDecypt"),
  },
  data() {
    return {
      tabList: [],
      url: "",
      emUrl: "",
      notice: "",
      showSave: false,
      advShow: false,
      advList: [],
      downBanner: [],
      popUpList: [], // 弹窗广告
      activeIndex: 0,
    };
  },
  mounted() {
    this.getTabs();
    this.generateCode();
    setTimeout(() => {
      visitRecord();
    }, 5000);
  },
  methods: {
    async getTabs() {
      let res = await this.$Api(getTabs);
      if (res && res.code == 200) {
        this.tabList = res.data.xsmMenuDataList || [];
        this.notice = res.data.notice;
        this.url = res.data.officialWebsite;
        this.advList = res.data.banner || [];
        this.downBanner = res.data.downBanner || [];
        this.popUpList = res.data.popUp || [];
        if (this.popUpList.length > 0) {
          this.advShow = true;
        }
      }
    },
    getInfo(data) {
      this.advList = data.banner || [];
      this.downBanner = data.downBanner || [];
    },
    close() {
      this.popUpList.pop();
      if (this.popUpList.length == 0) {
        this.advShow = false;
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
    // 生成二维码
    generateCode() {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: window.location.href,
          width: 170,
          height: 170,
          colorDark: "rgb(74, 141, 200)",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    // 下载二维码
    downloadQrcode() {
      domToImage.toPng(this.$refs.qrCodeUrl).then((dataUrl) => {
        var link = document.createElement("a");
        link.download = "qrcode.png";
        link.href = dataUrl;
        link.click();
      });
    },
    // 保存链接
    saveLink() {
      let req = window.location.href;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
    /**
     * * 跳转广告
     *  * @param {*} item 广告信息
     */
    goAdv(item) {
      item.type = "adv";
      jumpAdvs(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100%;
  /deep/ .van-tabs {
    height: 100%;
    .van-tabs__wrap {
      height: 30px;
      padding: 0 10px;
      margin-top: 16px;
      .van-tabs__nav {
        justify-content: center;
      }
      .van-tab {
        font-size: 14px;
        color: #a6afb8;
        border: 1px solid #a6afb8;
        border-radius: 30px;
        flex: unset;
        width: 80px;
        margin: 0 7px;
      }
      .van-tab--active {
        font-size: 13px;
        color: #ffb200;
        border: 1px solid #ffb200;
        border-radius: 30px;
      }
      .van-tabs__line {
        display: none;
      }
      .van-tabs__nav--line {
        padding-bottom: 3px;
      }
    }
    .van-tabs__content {
      height: 100%;
      .van-tab__pane,
      .van-tab__pane-wrapper {
        height: 100%;
      }
    }
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fbd45f;
    height: 55px;
    padding: 0 14px;
    .leftBox {
      display: flex;
      align-items: center;
      .logo {
        width: 34px;
        height: 34px;
        border-radius: 6px;
        background: url("./../../assets/png/logo.png") no-repeat;
        background-size: 100% 100%;
      }
      .appInfo {
        margin-left: 5px;
        .title {
          font-size: 10px;
          font-weight: 800;
        }
        .desc {
          color: #ffffff;
          font-size: 10px;
        }
      }
    }
    .rightBox {
      width: 64px;
      height: 22px;
      background: #ffffff;
      color: #ff383f;
      text-align: center;
      line-height: 22px;
      font-size: 10px;
      border-radius: 22px;
      cursor: pointer;
    }
  }
  .headerTip {
    font-size: 10px;
    padding: 0px 12px;
    color: #a0a1a6;
    margin-top: 13px;
    .navName {
      font-size: 11px;
      font-weight: 600;
      text-decoration: none;
    }
    span {
      text-decoration: underline;
    }
  }
  .advSwiper {
    border-radius: 10px;
    overflow: hidden;
    margin: 7px 15px 5px;
    /deep/ .swiper-container {
      height: 112px;
    }
    /deep/ img {
      height: 100%;
    }
  }
  .downBanner {
    overflow: hidden;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 5;
    /deep/ .swiper-container {
      height: 60px;
    }
    /deep/ img {
      height: 100%;
    }
  }
  .advertise_box {
    height: 433px;
    width: 300px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    .announcement_box_img {
      width: 300px;
      height: 391.6px;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      .content_img {
        width: 300px;
        max-height: 391.6px;
      }
    }
    .imgBtn {
      width: 30px;
      height: 30px;
      background: url("../../assets/png/close.png") 100%/100% no-repeat;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .qrcodeBox {
    width: 100%;
    height: 100%;
    background: linear-gradient(62deg, #2b24f2, #ff006f);
    background-size: 180%;
    background-position: 0 100%;
    color: white;
    .qrcodeTitle {
      font-size: 12px;
      text-align: center;
      padding: 9px;
    }
    .qrcode {
      height: 200px;
      width: 200px;
      color: black;
      background-color: white;
      margin: 0 auto 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btnBox {
      border: 1px solid white;
      width: 160px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      margin: 0 auto 15px;
      border-radius: 35px;
      cursor: pointer;
    }
    .kindTip {
      font-size: 12.6px;
      padding: 0 12px;
      line-height: 18px;
    }
  }
}
@media screen and (min-width: 530px) {
  .downBanner {
    position: absolute !important;
  }
}
</style>
