<template>
  <div class="blackMaterial">
    <iframe
      v-if="internalUrl"
      :src="internalUrl"
      frameborder="0"
      class="iframe"
      :style="{ height: downBanner.length > 0 ? '60%' : '90%' }"
    ></iframe>
    <div class="sizeBox"></div>
  </div>
</template>
<script>
import { getHomeApps } from "@/api/home";

export default {
  nema: "yuePao",
  props: {
    internalUrl: String,
    moduleId: String,
  },
  data() {
    return {
      downBanner: [],
    };
  },
  created() {
    this.getApps();
  },
  methods: {
    async getApps() {
      let res = await this.$Api(getHomeApps, this.moduleId);
      this.loading = false;
      if (res && res.code == 200) {
        this.downBanner = res.data.downBanner || [];
        this.$emit("getInfo", res.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blackMaterial {
  height: 100%;
  width: 100%;
  .iframe {
    width: 100%;
    height: 60%;
    overflow-y: hidden;
  }
  .sizeBox {
    height: 200px;
  }
}
</style>
