<template>
  <div class="tabItem">
    <div class="main" v-if="!loading">
      <div class="appBoxs" v-if="hengApp.length">
        <div
          class="appBox"
          @click="jump(item)"
          v-for="item in hengApp"
          :key="item.id"
        >
          <ImgDecypt :key="item.id" :src="item.icon" class="icon"></ImgDecypt>
          <div class="name">{{ item.name }}</div>
          <div class="install">{{ item.type == "app" ? "安装" : "打开" }}</div>
        </div>
      </div>
      <!-- 轮播图广告 -->
      <AdvCenterSwiper
        :advList="advList"
        v-if="advList.length"
        :key="advList[0].id"
        :hasPagination="false"
        class="advSwiper"
      />
      <div class="appTextBoxs">
        <div
          class="appText"
          v-for="item in textApp"
          :key="item.id"
          @click="jump(item)"
        >
          <div class="name">{{ item.name }}</div>
          <div class="arrow"></div>
        </div>
      </div>
      <div
        class="appItem"
        @click="jump(item)"
        v-for="item in shuApp"
        :key="item.id"
      >
        <div class="leftBox">
          <ImgDecypt :key="item.id" :src="item.icon" class="cover"></ImgDecypt>
          <div class="appInfo">
            <div class="appName">
              {{ item.name }}
            </div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
        <div class="open">打开</div>
      </div>
      <NoData v-if="!hengApp.length && !shuApp.length && !textApp.length" />
    </div>
    <Loading v-else />
  </div>
</template>
<script>
import { getHomeApps } from "@/api/home";
import { jumpAdvs } from "@/utils/getConfig";
import Loading from "@/components/Loading/index.vue";
import NoData from "@/components/NoData/index.vue";
import AdvCenterSwiper from "@/components/AdvCenterSwiper";

export default {
  name: "tabItem",
  components: {
    ImgDecypt: () => import("@/components/ImgDecypt"),
    Loading,
    NoData,
    AdvCenterSwiper
  },
  props: {
    moduleId: String,
  },
  data() {
    return {
      hengApp: [],
      shuApp: [],
      textApp: [],
      loading: true,
      advList: [],
    };
  },
  created() {
    this.getApps();
  },
  methods: {
    async getApps() {
      let res = await this.$Api(getHomeApps, this.moduleId);
      this.loading = false;
      if (res && res.code == 200) {
        this.hengApp = res.data.hengApp || [];
        this.shuApp = res.data.shuApp || [];
        this.textApp = res.data.text || [];
        this.advList = res.data.centralBanner || [];
        this.$emit("getInfo", res.data);
      }
    },
    jump(data) {
      data.type = "app";
      jumpAdvs(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabItem {
  padding: 15px 0px 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 241px);
  .main {
    padding-bottom: 200px;
    .appBoxs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-row-gap: 14px;
      // grid-column-gap: 24px;
      margin-bottom: 20px;
      padding: 0 10px;
      .appBox {
        width: 66px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon {
          height: 55px;
          width: 55px;
          border-radius: 8px;
          overflow: hidden;
        }
        .name {
          font-size: 11px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          margin: 4px 0 0px;
        }
        .install {
          height: 25px;
          width: 60px;
          border-radius: 30px;
          background: #fbd45f;
          text-align: center;
          line-height: 25px;
          font-size: 12px;
          margin: 0 auto;
        }
      }
    }
    .advSwiper {
      border-radius: 10px;
      overflow: hidden;
      margin: 0 15px 18px;
      /deep/ .swiper-container {
        height: 112px;
      }
      /deep/ img {
        height: 100%;
      }
    }
    .appTextBoxs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 10px;
      grid-column-gap: 13px;
      margin-bottom: 30px;
      padding: 0 10px;
      cursor: pointer;
      .appText {
        height: 33px;
        width: 103px;
        color: #000000;
        background: #fcd361;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 11px;
        box-sizing: border-box;
        .name {
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          width: 73px;
        }
        .arrow {
          height: 11px;
          width: 6px;
          background: url("./../../../assets/png/arrow.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .appItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fbd45f;
      margin: 15px 10px;
      padding: 5px 10px;
      cursor: pointer;
      .leftBox {
        display: flex;
        align-items: center;
        .cover {
          height: 50px;
          width: 50px;
          border-radius: 8px;
          overflow: hidden;
        }
        .appInfo {
          width: 180px;
          margin-left: 10px;
          .appName {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .desc {
            font-size: 12px;
            color: #999fad;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 3px;
          }
        }
      }
      .open {
        height: 25px;
        width: 60px;
        border-radius: 30px;
        background: #ffffff;
        color: #ff383f;
        text-align: center;
        line-height: 25px;
        font-size: 12px;
      }
    }
  }
}
</style>
