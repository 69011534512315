<template>
  <div class="img_Decypt">
    <lazy-component class="laz_com">
      <LazyImg :itemData="itemData" @load="onload" :src="src" :isNeedDecypt="isNeedDecypt" :isSplash="isSplash" :round="round"/>
      <slot/>
    </lazy-component>
  </div>
</template>
<script>
import LazyImg from "@/components/LazyImg"

export default {
  props: ['src', 'isNeedDecypt', 'isSplash', 'round','itemData'],
  components: {
    LazyImg,
  },methods:{
        onload(){
            this.$emit("load");
        }
    }
}
</script>
<style scoped lang="scss">
.img_Decypt {
  height: 100%;

  .laz_com {
    height: 100%;
  }
}
</style>
